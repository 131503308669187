/* These CSS styles all apply to the SVG elements defined below */
#clock {
  /* styles for everything in the clock */
  stroke: black;
  /* black lines */
  stroke-linecap: round;
  /* with rounded ends */
  /*fill: #eef;*/
  fill: transparent;
  /* on a light blue gray background */
}
#face {
  stroke-width: 3px;
}
/* clock face outline */
#ticks {
  stroke-width: 2;
}
/* lines that mark each hour */
#hourhand {
  stroke: blue;
  stroke-width: 5px;
}
#minutehand {
  stroke: green;
  stroke-width: 3px;
}
#secondhand {
  stroke: red;
  stroke-width: 1px;
}
#numbers {
  /* how to draw the numbers */
  font-family: sans-serif;
  font-size: 7pt;
  font-weight: bold;
  text-anchor: middle;
  stroke: none;
  fill: black;
}
