.animate__delay-250ms {
  animation-delay: 250ms;
}

.animate__delay-500ms {
  animation-delay: 500ms;
}

.animate__delay-750ms {
  animation-delay: 750ms;
}

.animate__delay-1250ms {
  animation-delay: 1250ms;
}

.animate__delay-1500ms {
  animation-delay: 1500ms;
}

.animate__delay-1750ms {
  animation-delay: 1750ms;
}

.animate__delay-2250ms {
  animation-delay: 2250ms;
}

.animate__delay-2500ms {
  animation-delay: 2500ms;
}

.animate__delay-2750ms {
  animation-delay: 2750ms;
}

.animate__delay-3250ms {
  animation-delay: 3250ms;
}

.animate__delay-3500ms {
  animation-delay: 1250ms;
}

.animate__delay-3750ms {
  animation-delay: 1250ms;
}

.animate__delay-4250ms {
  animation-delay: 1250ms;
}

.animate__delay-4500ms {
  animation-delay: 1250ms;
}

.animate__delay-4750ms {
  animation-delay: 1250ms;
}

.animate__delay-5250ms {
  animation-delay: 1250ms;
}

.animate__delay-5500ms {
  animation-delay: 1250ms;
}

.animate__delay-5750ms {
  animation-delay: 1250ms;
}
