.form-group {
  margin-bottom: .25rem;
}
label {
  font-size: 12px;
  margin-bottom: 0 !important;
  word-break: keep-all;
  white-space: nowrap;
}
input,input:focus,textarea,select {
  box-shadow: inset .15rem .15rem .25rem rgba(0,0,0,.25);
}
.shadow-inset:focus {
  border-color: DodgerBlue;
}
