@import '~bootstrap/dist/css/bootstrap.css';
@import '~animate.css/animate.css';
@import '~tootik/css/tootik.css';

body {
  min-height: 100vh;
  padding-top: 3.75rem;
  background: #f5f5f5;
  overflow-x: hidden;
}

.logo {
  height: 31px;
  float: left;
  color: white;
  font-weight: bolder;
  margin-right: 1rem;
  font-size: 1.5rem;
  letter-spacing: -2px;
}

details > summary {
  outline: 0 !important;
  cursor: pointer !important;
  padding: 0.5rem 1rem;
  border-radius: 50rem;
}

details > summary:hover {
  background: rgba(0, 0, 0, 0.05);
}

.place-items-center {
  display: grid;
  place-items: center;
}

.text-shadow {
  text-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.5);
}

.text-shine {
  text-shadow: 0 0.25rem 0.75rem rgba(255, 255, 255, 0.5);
}

.shadow-hover:hover {
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.15) !important;
}

.shine-hover:hover {
  box-shadow: 0 0 3rem rgba(255, 255, 255, 0.25) !important;
}

.sticky-bottom {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
