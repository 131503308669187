textarea.CTE {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  box-shadow: none !important;
  border: none !important;
  background: inherit;
  padding: 0 !important;
  margin: 0 !important;
  resize: none;
  text-align: inherit !important;
  font-weight: inherit !important;
  vertical-align: bottom;
}
