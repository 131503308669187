.SlayteWrapImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.SlayteWrapImages > img {
  display: flex;
  flex-direction: column;
}
