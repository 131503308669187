#root > .navbar {
  background: linear-gradient(90deg, tomato, fuchsia) !important;
}

#Home {
  margin-top: -5rem; /* to cancel out body padding-top */
}

#Home > div:first-child {
  background: url('/img/unsplash/photo-1468276311594-df7cb65d8df6.jpeg') 0 0 /
    cover no-repeat;
  height: 512px;
}

.HomeListEntry {
  /*background: rgba(255, 255, 255, 0.75);*/
  background: white;
  padding: 1rem;
  color: darkslategray;
}

/* FIXME: not working, underline still showing on focus */
a:hover .HomeListEntry * {
  text-decoration: none !important;
}

.app-icon {
  border-radius: 0.75rem;
}

.download-appstore-btn {
  display: inline-block;
  background: url(/img/download-appstore.svg) no-repeat;
  background-size: contain;
  padding: 0.25rem 3rem;
}
a.download-appstore-btn::after {
  content: none;
  margin: 0;
}
